export const LogoMartinsSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="102"
    height="97"
    fill="none"
    viewBox="0 0 102 97"
  >
    <mask
      id="mask0_125_752"
      style={{ maskType: 'alpha' }}
      width="40"
      height="38"
      x="62"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M62.293.229H102v37.272H62.293V.23z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g mask="url(#mask0_125_752)">
      <path
        fill="#A1ACAF"
        fillRule="evenodd"
        d="M76.436 32.96L62.293 8.602 67.068.229l16.434 28.277c.221.343.576.538.976.538H102V37.5H84.478c-3.266 0-6.197-1.654-8.042-4.54z"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#A1ACAF"
      fillRule="evenodd"
      d="M67.423 37.903L56.46 18.846l5.046-8.847 13.777 23.725c2.102 3.293 5.453 5.185 9.195 5.185H102v8.469H84.019c-6.873 0-13.08-3.542-16.596-9.475z"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask1_125_752"
      style={{ maskType: 'alpha' }}
      width="51"
      height="38"
      x="51"
      y="20"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M51 20.19h51v36.968H51V20.19z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g mask="url(#mask1_125_752)">
      <path
        fill="#A1ACAF"
        fillRule="evenodd"
        d="M55.684 20.19l10.583 18.396c3.767 6.355 10.404 10.145 17.752 10.145H102v8.427H84.174c-15.261 0-22.862-10.926-24.827-14.273L51 28.404l4.684-8.214z"
        clipRule="evenodd"
      ></path>
    </g>
    <mask
      id="mask2_125_752"
      style={{ maskType: 'alpha' }}
      width="40"
      height="38"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 .229h39.707v37.272H0V.23z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g mask="url(#mask2_125_752)">
      <path
        fill="#A1ACAF"
        fillRule="evenodd"
        d="M25.564 32.96L39.707 8.602 34.932.229 18.498 28.506c-.22.343-.576.538-.976.538H0V37.5h17.522c3.267 0 6.197-1.654 8.042-4.54z"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#A1ACAF"
      fillRule="evenodd"
      d="M34.577 37.903L45.54 18.846l-5.046-8.847-13.777 23.725c-2.102 3.293-5.453 5.185-9.195 5.185H0v8.469h17.981c6.873 0 13.08-3.542 16.596-9.475z"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask3_125_752"
      style={{ maskType: 'alpha' }}
      width="51"
      height="38"
      x="0"
      y="20"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 20.19h51v36.968H0V20.19z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g mask="url(#mask3_125_752)">
      <path
        fill="#A1ACAF"
        fillRule="evenodd"
        d="M46.316 20.19L35.733 38.586C31.965 44.941 25.33 48.731 17.98 48.731H0v8.427h17.827c15.26 0 22.86-10.926 24.826-14.273L51 28.404l-4.684-8.214z"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#004282"
      fillRule="evenodd"
      d="M21.16 78.558l-2.654-15.982H13.01l-2.396 9.278-2.419-9.278H2.701L.048 78.558H5.56l1.3-7.937 2.069 7.937h3.348l2.068-7.937 1.318 7.937h5.495zm9.962-5.848l-1.352-4.46-1.363 4.46h2.715zM21.4 78.558l4.886-15.982h6.968l4.885 15.982H32.92l-.606-1.984h-5.077l-.618 1.984H21.4zm22.502-11.907h1.017c.51 0 1.536.338 1.536 1.567 0 1.138-.896 1.566-1.536 1.566h-1.017v-3.133zm0 11.907v-6.256l2.758 6.256h5.722l-2.81-6.51c.378-.174 2.273-1.657 2.273-4.315 0-3.607-2.978-5.157-5.022-5.157H38.47v15.982h-.339 5.772zM56.306 67.8h-3.22v-5.224h11.845V67.8h-3.22v10.758h-5.405V67.8zm9.85 10.758h5.403V62.576h-5.404v15.982zm6.709 0V62.576h5.404l4.861 8.357v-8.357h5.435v15.982H83.05l-4.782-8.357v8.357h-5.404zm18.5-5.336c1.16.769 2.36 1.188 3.262 1.274.505.056 1.192-.055 1.365-.67.117-.654-.343-1.04-1.123-1.191-4.054-.641-5.178-3.322-5.173-5.343 0-1.726 1.515-5.028 6.733-5.028.92 0 1.738.105 2.657.312.817.21 1.734.63 2.654 1.15l-2.116 3.634c-.61-.378-1.762-.768-2.443-.85-.56-.07-1.22-.033-1.5.558-.138.308-.017 1.001 1.144 1.233 4.376.814 5.104 3.693 5.127 5.257 0 2.227-1.905 5.086-6.5 5.086-3.675 0-5.16-1.092-6.202-1.863l2.114-3.56z"
      clipRule="evenodd"
    ></path>
  </svg>
)
