import { ErrorSvg } from 'common/icons/ErrorSvg'
import { ErrorResponse } from 'common/interfaces/services/error-response'
import { StatusCode } from 'status-code-enum'
import { ImageError, MessageError } from './styles'

interface ContentErrorProps {
  errorResponse?: ErrorResponse
}
const ContentError = ({ errorResponse }: ContentErrorProps) => {
  return (
    <>
      <ImageError>{ErrorSvg} </ImageError>
      <MessageError className="pt-7">
        {errorResponse &&
        errorResponse.status === StatusCode.ServerErrorInternal
          ? 'Ocorreu um erro ao buscar o pedido.'
          : 'Infelizmente não encontramos nenhum pedido.'}
      </MessageError>
      <MessageError className="pt-2">Tente novamente!</MessageError>
    </>
  )
}

export default ContentError
