import { getDateOrUndefined } from 'common/utils/date'
import { IconType } from 'react-icons'
import { BiCheck } from 'react-icons/bi'
import { FiTruck } from 'react-icons/fi'
import { BsReceipt } from 'react-icons/bs'
import { Evento } from 'redux/rastreamento/types'

export type MarcoTimelineEvent = {
  name: string
  active: boolean
  date?: Date
  icon: IconType
}

export const makeTimeline = (events: Evento[]): MarcoTimelineEvent[] => {
  const pedidoRecebidoDate = events.find(
    e => e.descricao.toLowerCase() === 'pedido recebido'
  )?.data
  const emTransferenciaDate = events.find(
    e => e.descricao.toLowerCase() === 'em transferência'
  )?.data
  const saiuParaEntregaDate = events.find(
    e => e.descricao.toLowerCase() === 'saiu para entrega'
  )?.data
  const entregueDate = events.find(
    e => e.descricao.toLowerCase() === 'entrega realizada'
  )?.data

  return [
    {
      name: 'Pedido recebido',
      active: [
        pedidoRecebidoDate,
        emTransferenciaDate,
        saiuParaEntregaDate,
        entregueDate
      ].some(e => e !== undefined),
      date: getDateOrUndefined(pedidoRecebidoDate),
      icon: BsReceipt
    },
    {
      name: 'Em transferência',
      active: [emTransferenciaDate, saiuParaEntregaDate, entregueDate].some(
        e => e !== undefined
      ),
      date: getDateOrUndefined(emTransferenciaDate),
      icon: FiTruck
    },
    {
      name: 'Saiu para entrega',
      active: [saiuParaEntregaDate, entregueDate].some(e => e !== undefined),
      date: getDateOrUndefined(saiuParaEntregaDate),
      icon: FiTruck
    },
    {
      name: 'Entregue',
      active: entregueDate !== undefined,
      date: getDateOrUndefined(entregueDate),
      icon: BiCheck
    }
  ]
}
