import RastreamentoFeature from 'features/rastreamento'
import { useSearchParams } from 'react-router-dom'

const RastreamentoPage = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  return <RastreamentoFeature token={token} />
}

export default RastreamentoPage
