import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 40px;
  margin-top: 10px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.colors.neutral.soft};
`

export const Value = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: ${props => props.theme.colors.primary};
`
