import { FiMapPin, FiTruck } from 'react-icons/fi'
import styled, { css } from 'styled-components'

type ColumnProps = {
  hasLeftDivider?: boolean
}

export const Grid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 5fr 2fr;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`

const columnModifiers = {
  leftDivider: () => css`
    padding-left: 40px;
    border-left: 1px solid ${props => props.theme.colors.neutral.soft};
  `
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 16px;

  @media (max-width: 1024px) {
    &:last-child {
      border-top: 1px solid ${props => props.theme.colors.neutral.soft};
    }
  }

  @media (min-width: 1025px) {
    ${props => props.hasLeftDivider && columnModifiers.leftDivider};
  }
`

export const Row = styled.div<ColumnProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

export const MapIcon = styled(FiMapPin)`
  width: 20px;
  height: 25px;
  color: ${props => props.theme.colors.neutral.soft};
`

export const TruckIcon = styled(FiTruck)`
  width: 20px;
  height: 25px;
  color: ${props => props.theme.colors.neutral.soft};
`
