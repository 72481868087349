import { dateFormatter } from 'common/utils/date'
import { Card } from 'components/card'
import { Evento } from 'redux/rastreamento/types'
import { TimelineEvent } from '../timeline-event'
import { Text, TextWrapper } from './styles'

type TimelineProps = {
  eventos: Evento[]
}

export const Timeline = ({ eventos }: TimelineProps) => {
  if (eventos.length === 0) {
    return (
      <Card title="Últimas atualizações">
        <TextWrapper>
          <Text>Não há eventos</Text>
        </TextWrapper>
      </Card>
    )
  }

  return (
    <Card title="Últimas atualizações">
      {[...eventos]
        .sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())
        .map((evento, idx) => (
          <TimelineEvent
            key={idx}
            date={dateFormatter(new Date(evento.data), true)}
            description={evento.descricao}
            observation={evento.observacao}
          />
        ))}
    </Card>
  )
}
