import styled from 'styled-components'

export const ImageError = styled.div`
  display: flex;
  padding-top: 20vh;
  justify-content: center;
`

export const MessageError = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
`
