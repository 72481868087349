export const ErrorSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="169"
    height="160"
    fill="none"
    viewBox="0 0 169 160"
  >
    <circle cx="84" cy="82" r="57" fill="#FFEBEE" opacity="0.4"></circle>
    <path
      fill="#B91B1A"
      d="M52.598 60.9a2 2 0 000 2.829l19.18 19.18-19.364 19.362a2 2 0 000 2.829L62.9 115.585a2 2 0 002.828 0l19.363-19.363 18.995 18.994a2 2 0 002.828 0l10.485-10.485a2 2 0 000-2.828L98.405 82.908l18.811-18.81a2 2 0 000-2.83l-10.485-10.485a2 2 0 00-2.829 0l-18.81 18.811-19.18-19.18a2 2 0 00-2.829 0L52.598 60.9z"
      opacity="0.4"
    ></path>
    <path
      fill="#E73933"
      d="M51.77 60.071a2 2 0 000 2.829l19.179 19.18-19.363 19.362a2 2 0 000 2.828l10.485 10.486a2 2 0 002.829 0l19.362-19.363 18.995 18.994a2 2 0 002.828 0l10.486-10.485a2 2 0 000-2.829L97.576 82.079l18.811-18.81a2 2 0 000-2.83l-10.485-10.485a2 2 0 00-2.829 0l-18.81 18.811-19.18-19.18a2 2 0 00-2.828 0L51.769 60.072z"
    ></path>
    <ellipse cx="108.5" cy="20.5" fill="#E73933" rx="4.5" ry="4.5"></ellipse>
    <ellipse cx="16.5" cy="99.5" fill="#E73933" rx="4.5" ry="4.5"></ellipse>
    <circle cx="164.5" cy="94.5" r="4.5" fill="#E73933"></circle>
    <circle cx="22.5" cy="132.5" r="4.5" fill="#E73933"></circle>
    <ellipse cx="56" cy="146" fill="#E73933" rx="2" ry="2"></ellipse>
    <ellipse cx="89" cy="158" fill="#E73933" rx="2" ry="2"></ellipse>
    <ellipse cx="4" cy="31" fill="#E73933" rx="2" ry="2"></ellipse>
    <circle cx="152" cy="18" r="2" fill="#E73933"></circle>
    <circle cx="152" cy="74" r="2" fill="#F09A99"></circle>
    <circle cx="167" cy="44" r="2" fill="#F09A99"></circle>
    <ellipse cx="25" cy="6" fill="#F09A99" rx="2" ry="2"></ellipse>
    <ellipse cx="143" cy="112" fill="#E73933" rx="2" ry="2"></ellipse>
    <ellipse cx="130" cy="139" fill="#F09A99" rx="2" ry="2"></ellipse>
    <circle cx="152" cy="139" r="2" fill="#F09A99"></circle>
    <ellipse cx="2" cy="118" fill="#F09A99" rx="2" ry="2"></ellipse>
    <ellipse cx="145" cy="48" fill="#E73933" rx="2" ry="2"></ellipse>
    <ellipse cx="6" cy="82" fill="#E73933" rx="2" ry="2"></ellipse>
    <ellipse cx="45" cy="25" fill="#E73933" rx="2" ry="2"></ellipse>
    <ellipse cx="19" cy="55" fill="#E73933" rx="2" ry="2"></ellipse>
    <ellipse cx="79.5" cy="4.5" fill="#F09A99" rx="4.5" ry="4.5"></ellipse>
  </svg>
)
