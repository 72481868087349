import { ReactNode } from 'react'
import { CardHeader, Container, Title } from './styles'

type CardProps = {
  title?: string
  children: ReactNode
  fullWidth?: boolean
  fullHeight?: boolean
  addon?: {
    header: ReactNode
  }
  minHeight?: number
  maxHeight?: number
}

export const Card = ({
  title,
  children,
  addon,
  fullWidth = true,
  fullHeight = false,
  minHeight,
  maxHeight
}: CardProps) => (
  <Container
    fullWidth={fullWidth}
    fullHeight={fullHeight}
    minHeight={minHeight}
    maxHeight={maxHeight}
  >
    {(title || addon?.header) && (
      <CardHeader>
        <Title>{title}</Title>
        {addon?.header}
      </CardHeader>
    )}
    {children}
  </Container>
)
