import { Evento } from 'redux/rastreamento/types'
import { ItemTimelineMacro } from './item-timeline-macro'
import { makeTimeline } from './make-timeline'
import { Container, Content } from './styles'

interface MacroTimelineProps {
  eventos: Evento[]
}
export const MacroTimeline = ({ eventos }: MacroTimelineProps) => {
  const timeline = makeTimeline(eventos)

  return (
    <Container>
      <Content>
        {timeline?.map((event, index) => (
          <div key={index}>
            <ItemTimelineMacro
              event={event}
              disabledLine={
                index == timeline.length - 1
                  ? event.active
                  : timeline[index + 1].active
              }
              lastIndex={index !== timeline.length - 1}
            />
          </div>
        ))}
      </Content>
    </Container>
  )
}
