import { LogoMartinsSvg } from 'common/icons/LogoMartinsSvg'
import { format } from 'date-fns'
import { ReactNode } from 'react'
import { Content, Footer, Logo } from './styles'

interface LayoutProps {
  children: ReactNode
  time: number | undefined
}
const Layout = ({ children, time }: LayoutProps) => {
  return (
    <>
      <Logo>{LogoMartinsSvg}</Logo>
      <Content>{children}</Content>
      <Footer>
        Consulta realizada em {time && format(time, "dd/MM/yyyy 'às' HH:mm:ss")}
      </Footer>
    </>
  )
}

export default Layout
