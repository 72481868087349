import styled from 'styled-components'

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`

export const Content = styled.div`
  background: #f4f6fc;
  min-height: 78vh;
  padding-top: 24px;

  @media only screen and (min-width: 1024px) {
    padding-left: 145px;
    padding-right: 145px;
  }
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d7384;
  min-height: 5vh;
`
