import { dateFormatter } from 'common/utils/date'
import { Card } from 'components/card'
import { OrderInformationField } from '../order-information-field'

import { Column, Grid, MapIcon, Row, TruckIcon } from './styles'

type OrderInformationProps = {
  entrega?: string
  destino: string
  transportador: string
  notaFiscal?: number
  emitidaEm?: Date
}

export const OrderInformationCard = ({
  entrega,
  destino,
  transportador,
  notaFiscal,
  emitidaEm
}: OrderInformationProps) => {
  return (
    <Card title="Informações do pedido" minHeight={220}>
      <Grid>
        <Column>
          <Row>
            {entrega && (
              <OrderInformationField label="Entrega" value={entrega} />
            )}

            <OrderInformationField
              label="Nota Fiscal"
              value={notaFiscal ?? 'Não há nota'}
              valueClass={notaFiscal ? 'text-success' : ''}
            />
            <OrderInformationField
              label="Emitida em"
              value={emitidaEm ? dateFormatter(emitidaEm, true) : '-'}
            />
          </Row>
        </Column>

        <Column hasLeftDivider>
          <OrderInformationField
            Icon={MapIcon}
            label="Destino"
            value={destino ?? 'Desconhecido'}
          />
          <OrderInformationField
            Icon={TruckIcon}
            label="Transportador"
            value={transportador ?? 'Desconhecido'}
          />
        </Column>
      </Grid>
    </Card>
  )
}
