import styled from 'styled-components'

export const TextWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 24px;
  border: 2px solid #e9ebf1;
`

export const Text = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray.dark};

  font-size: 24px;
`

export const SummaryContent = styled.div`
  display: flex;
  align-items: center;
`
