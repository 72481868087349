import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import 'react-loading-skeleton/dist/skeleton.css'
import App from './App'
import './index.css'

const root = ReactDOMClient.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
