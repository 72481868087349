import { api } from 'redux/service/api'
import { BffRoute } from 'services/bff-routes'
import { Rastreamento } from './types'

export const rastreamentoApi = api.injectEndpoints({
  endpoints: build => ({
    getOrder: build.query<Rastreamento, string>({
      query: param => {
        return {
          url: BffRoute.RastreamentoPublico(encodeURIComponent(param))
        }
      }
    })
  }),
  overrideExisting: true
})

export const { useGetOrderQuery } = rastreamentoApi
