import ShowMoreText from 'react-show-more-text'
import {
  DateColumn,
  EventColumn,
  ObservationContainer,
  RowContainer
} from './styles'

type TimelineEventProps = {
  date: string
  description: string
  observation?: string
}

export const TimelineEvent = ({
  date,
  description,
  observation
}: TimelineEventProps) => (
  <RowContainer>
    <EventColumn className="pr-3">{description}</EventColumn>
    <DateColumn>{date}</DateColumn>
    {observation && (
      <ObservationContainer>
        <ShowMoreText
          lines={1}
          more="Ler mais"
          less="Ler menos"
          anchorClass="text-primary font-medium"
          width={500}
        >
          {observation}
        </ShowMoreText>
      </ObservationContainer>
    )}
  </RowContainer>
)
