import { Grid } from '@mui/material'
import { getDateOrUndefined } from 'common/utils/date'
import { Evento, Nota, Pedido } from 'redux/rastreamento/types'
import { DeliveryForecastCard } from '../delivery-forecast-card'
import { ItemsCard } from '../items-card'
import { OrderInformationCard } from '../order-information-card'
import { Timeline } from '../timeline'

interface ContentTimelineProps {
  isDesktop: boolean
  order: Pedido
  nota: Nota | undefined
  origem: number
}

const ContentTimeline = ({
  isDesktop,
  order,
  nota,
  origem
}: ContentTimelineProps) => {
  const getDeliveredDate = (eventos: Evento[]): Date | undefined =>
    getDateOrUndefined(
      eventos.find(e => e.descricao.toLowerCase() === 'entrega realizada')?.data
    )

  const ORIGEM_SELLER = 3
  return (
    <Grid container direction="column" spacing={2}>
      <Grid
        item
        xs
        container
        direction={isDesktop ? 'row' : 'column'}
        spacing={2}
      >
        <Grid item xs>
          <OrderInformationCard
            entrega={origem !== ORIGEM_SELLER ? order.numeroPedido : undefined}
            notaFiscal={nota ? nota.numero : undefined}
            emitidaEm={
              nota ? getDateOrUndefined(nota.dataImpressao) : undefined
            }
            destino={order.destino}
            transportador={order.transportadora}
          />
        </Grid>
        <Grid item xs style={{ maxWidth: '278px', height: '100%' }}>
          <DeliveryForecastCard
            forecast={getDateOrUndefined(order.previsaoEntrega)}
            prevForecast={getDateOrUndefined(order.previsaoEntregaAnterior)}
            deliveredDate={getDeliveredDate(order.eventos)}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs
        container
        direction={isDesktop ? 'row' : 'column'}
        spacing={2}
      >
        <Grid item xs>
          <Timeline eventos={order.eventos} />
        </Grid>

        {order?.cortes && order.cortes.quantidadeCortes > 0 && (
          <Grid item xs style={{ maxWidth: '278px' }}>
            <ItemsCard
              quantidadeItens={order.cortes.quantidadeItens}
              quantidadeCortes={order.cortes.quantidadeCortes}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default ContentTimeline
