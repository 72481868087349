import { createTheme } from '@mui/material'
import { ptBR as corePtBR } from '@mui/material/locale'

export const muiTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#2B52DD'
      },
      secondary: { main: '#004282' },
      success: { main: '#3EB96F', light: '#D8F1E2' },
      warning: { main: '#FBC436', light: '#FEF3D7' },
      error: { main: '#E73A33', light: '#FAD8D6' }
    },
    typography: {
      fontFamily: ['Rubik'].join(',')
    }
  },
  corePtBR
)
