import styled, { css } from 'styled-components'

type PrincipalTextProps = {
  isLate?: boolean
  hasPrevForecast?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 90%;
  padding-bottom: 16px;
`

const textColor = (hasPrevForecast: boolean, isLate: boolean) => {
  if (hasPrevForecast) {
    return css`
      color: ${props => props.theme.colors.primary};
    `
  } else if (isLate) {
    return css`
      color: ${props => props.theme.colors.suport.error};
    `
  }
  return css`
    color: ${props => props.theme.colors.suport.success};
  `
}

export const PrincipalText = styled.span<PrincipalTextProps>`
  margin: 8px 0px 8px;
  ${props => textColor(!!props.hasPrevForecast, !!props.isLate)}
  font-size: 32px;
  line-height: 39px;
`

export const Subtext = styled.span`
  color: ${props => props.theme.colors.neutral.soft};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
`

export const TagWarning = styled.div`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin: 16px 0 16px 0;
  color: ${props => props.theme.colors.suport.warning};
  background-color: ${props => props.theme.colors.suport.warningLight};
`
