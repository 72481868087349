import { Card } from 'components/card'
import { differenceInCalendarDays, format } from 'date-fns'
import { Container, PrincipalText, Subtext, TagWarning } from './styles'

type DeliveryForecastCardProps = {
  forecast?: Date
  prevForecast?: Date
  deliveredDate?: Date
}

export const DeliveryForecastCard = ({
  prevForecast,
  forecast,
  deliveredDate
}: DeliveryForecastCardProps) => {
  const mainText = forecast ? format(forecast, 'dd/MM/yyyy') : 'Sem previsão'

  const daysLate = deliveredDate
    ? differenceInCalendarDays(deliveredDate, forecast ?? new Date())
    : differenceInCalendarDays(new Date(), forecast ?? new Date())

  const isLate = daysLate > 0

  return (
    <Card title="Previsão de entrega" fullHeight>
      <Container>
        <PrincipalText
          isLate={!deliveredDate && isLate}
          hasPrevForecast={!deliveredDate && !!prevForecast}
        >
          {mainText}
        </PrincipalText>

        {prevForecast && (
          <>
            {!deliveredDate && <Subtext>Previsão de entrega alterada</Subtext>}
            <TagWarning>
              {`Previsão inicial: ${format(prevForecast, 'dd/MM/yyyy')}`}
            </TagWarning>
          </>
        )}

        {deliveredDate && <Subtext>Entregue</Subtext>}

        {isLate && (
          <Subtext>
            {deliveredDate ? 'com' : 'há'} {daysLate} dia(s) em atraso
          </Subtext>
        )}
      </Container>
    </Card>
  )
}
