import format from 'date-fns/format'
import { MarcoTimelineEvent } from './make-timeline'
import {
  Circle,
  ContentItem,
  Item,
  ItemLabel,
  ItemLine,
  LabelTime,
  LabelTitle,
  Line
} from './styles'

interface ItemTimelineMacroProps {
  event: MarcoTimelineEvent
  disabledLine: boolean
  lastIndex: boolean
}

export const ItemTimelineMacro = ({
  event,
  lastIndex,
  disabledLine
}: ItemTimelineMacroProps) => {
  const { icon: Icon } = event

  return (
    <ContentItem>
      <Item>
        <ItemLine>
          <Circle disabled={event.active}>
            <Icon />
          </Circle>
          {lastIndex && <Line disabled={disabledLine} />}
        </ItemLine>
        <ItemLabel>
          <LabelTitle disabled={event.active}>{event.name}</LabelTitle>
          {event.date && (
            <>
              <LabelTime disabled={event.active}>
                {format(event.date, 'dd/MM/yyyy')}
              </LabelTime>
              <LabelTime disabled={event.active}>
                {format(event.date, 'HH:mm')}
              </LabelTime>
            </>
          )}
        </ItemLabel>
      </Item>
    </ContentItem>
  )
}
