import { ThemeProvider as ThemeProviderMui } from '@mui/material'
import { muiTheme } from 'common/theme/mui-theme'
import { styledComponentTheme } from 'common/theme/styled-components-theme'
import DefaultPage from 'pages/default'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { store } from 'redux/store'
import { ThemeProvider } from 'styled-components'
import RastreamentoPage from './pages/rastreamento'
import { PageRoute } from './services/page-route'

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProviderMui theme={muiTheme}>
        <ThemeProvider theme={styledComponentTheme}>
          <BrowserRouter>
            <Routes>
              <Route path={PageRoute.Pedidos} element={<RastreamentoPage />} />
              <Route path="*" element={<DefaultPage />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ThemeProviderMui>
    </Provider>
  )
}

export default App
