export const dateFormatter = (
  date: Date,
  doesContainsTime?: boolean
): string => {
  return new Intl.DateTimeFormat('pt-BR', {
    timeStyle: doesContainsTime ? 'medium' : undefined,
    dateStyle: 'short'
  }).format(date)
}

export const getDateOrUndefined = (stringDate?: string): Date | undefined =>
  stringDate ? new Date(stringDate) : undefined
