import { rastreamentoApi } from './rastreamento/api'
import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { api } from './service/api'

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV == 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware, rastreamentoApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch
