import { IconType } from 'react-icons'
import { Container, Label, TextContainer, Value } from './styles'

type OrderInformationFieldProps = {
  label: string
  value: string | number
  valueClass?: string
  Icon?: IconType
}

export const OrderInformationField = ({
  label,
  value,
  valueClass,
  Icon
}: OrderInformationFieldProps) => {
  return (
    <Container>
      {Icon && <Icon />}
      <TextContainer>
        <Label>{label}</Label>
        <Value>
          <span className={valueClass}>{value}</span>
        </Value>
      </TextContainer>
    </Container>
  )
}
