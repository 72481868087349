import styled, { css } from 'styled-components'

interface CardStyleProps {
  fullWidth: boolean
  fullHeight: boolean
  minHeight?: number
  maxHeight?: number
}

const minHeightContainer = (props: CardStyleProps) => {
  if (props.minHeight)
    return css`
      min-height: ${props.minHeight}px;
    `
}
const maxHeightContainer = (props: CardStyleProps) => {
  if (props.maxHeight)
    return css`
      max-height: ${props.maxHeight}px;
    `
}

export const Container = styled.div<CardStyleProps>`
    background: ${props => props.theme.colors.neutral.white};
    border: 2px solid #e9ebf1;
    box-sizing: border-box;
    padding: 24px 20px 20px 20px;
    width:  ${props => (props.fullWidth ? '100%' : 'min-content')};
    height:  ${props => (props.fullHeight ? '100%' : 'min-content')};
    ${props => minHeightContainer(props)}
    ${props => maxHeightContainer(props)}
}
`

export const Title = styled.span`
  font-size: 18px;
  line-height: 22px;
`
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`
