import styled from 'styled-components'

export const Logo = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  height: 85vh;
  background: #f4f6fc;
  padding-left: 145px;
  padding-right: 145px;
  overflow: auto;
`

export const TabContainer = styled.span`
  @media (min-width: 1025px) {
    max-width: calc(100vw - 250px);
  }

  @media (max-width: 1025px) {
    max-width: 100vw;
  }
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d7384;
  height: 5vh;
`
