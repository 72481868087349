import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, Theme, useMediaQuery } from '@mui/material'
import Layout from 'common/layout'
import ContentError from 'features/components/content-error'
import ContentTimeline from 'features/components/content-timeline'
import { MacroTimeline } from 'features/components/macro-timeline'
import { SkeletonNegociation } from 'features/components/skeleton-negociation'
import { TabTitle } from 'features/components/tab-title'
import { useEffect, useState } from 'react'
import { useGetOrderQuery } from 'redux/rastreamento/api'
import { Evento, Pedido } from 'redux/rastreamento/types'
import { TabContainer } from './styles'

interface OrderTabProps {
  numeroPedido: string
  chaveNota?: string
  ultimoEvento: string
}

interface RastreamentoFeatureProps {
  token: string | null
}
const RastreamentoFeature = ({ token }: RastreamentoFeatureProps) => {
  const ORIGEM_SELLER = 3

  const { data, isLoading, fulfilledTimeStamp, isError, error } =
    useGetOrderQuery(token || '')
  const [activeCell, setActiveCell] = useState<string>('')
  const [orderTabs, setOrderTabs] = useState<OrderTabProps[]>()

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    if (data) {
      setActiveCell('0')
      const newOrderTabs = generateOrderTabs(data.pedidos)
      setOrderTabs(newOrderTabs)
    }
  }, [data])

  const generateOrderTabs = (pedidos: Pedido[]): OrderTabProps[] => {
    return pedidos
      .map((pedido: Pedido): OrderTabProps[] => {
        if (!pedido?.notas.length) {
          return [
            {
              numeroPedido: pedido.numeroPedido,
              chaveNota: undefined,
              ultimoEvento: getLastEvent(pedido.eventos).description
            }
          ]
        }

        return pedido.notas.map(n => {
          return {
            numeroPedido: pedido.numeroPedido,
            chaveNota: n.chave,
            ultimoEvento: getLastEvent(
              pedido.eventos.filter(
                x => !x.chaveNota || x.chaveNota === n.chave
              )
            ).description
          } as OrderTabProps
        })
      })
      .flat()
  }

  const getLastEvent = (eventos: Evento[]) => {
    return eventos.reduce(
      (acc, cur) => {
        if (acc.time < new Date(cur.data).getTime())
          return {
            description: cur.descricao,
            time: new Date(cur.data).getTime()
          }
        else return acc
      },
      { description: '', time: -Infinity }
    )
  }

  if (isError) {
    return (
      <Layout time={new Date().getTime()}>
        <ContentError errorResponse={error as any} />
      </Layout>
    )
  }

  if (isLoading || !data) {
    return (
      <Layout time={new Date().getTime()}>
        <SkeletonNegociation />
      </Layout>
    )
  }

  if (data?.origem === ORIGEM_SELLER) {
    return (
      <Layout time={fulfilledTimeStamp}>
        <ContentTimeline
          isDesktop={isDesktop}
          order={data?.pedidos[0]}
          nota={data?.pedidos[0]?.notas[0]}
          origem={data.origem}
        />
      </Layout>
    )
  }

  return (
    <>
      <Layout time={fulfilledTimeStamp}>
        <TabContext value={activeCell}>
          <TabContainer>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(_, newValue) => setActiveCell(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Pedidos"
              >
                {orderTabs?.map((order, idx) => (
                  <Tab
                    key={idx}
                    value={String(idx)}
                    label={
                      <TabTitle
                        key={`tab-${order.numeroPedido}-${idx}`}
                        title={`Pedido ${order.numeroPedido}`}
                        subtitle={order.ultimoEvento}
                        active={String(idx) === activeCell}
                      />
                    }
                  />
                ))}
              </TabList>
            </Box>
          </TabContainer>

          {data &&
            orderTabs?.map((o, idx) => {
              const order = data.pedidos.find(
                p => p.numeroPedido === o.numeroPedido
              )

              if (!order) return <>Erro ao filtrar pedido</>

              const nota = order.notas.find(x => x.chave === o.chaveNota)

              return (
                <TabPanel
                  key={`panel-${o.numeroPedido}-${idx}`}
                  value={String(idx)}
                >
                  <MacroTimeline eventos={order.eventos} />
                  <ContentTimeline
                    isDesktop={isDesktop}
                    order={order}
                    nota={nota}
                    origem={data.origem}
                  />
                </TabPanel>
              )
            })}
        </TabContext>
      </Layout>
    </>
  )
}

export default RastreamentoFeature
