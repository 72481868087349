import styled from 'styled-components'

type TimelineStatusStyleProps = {
  disabled?: boolean
}

export const Container = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
  width: 550px;
  height: 160px;

  @media (max-width: 1024px) {
    height: 100px;
  }
`

export const ContentItem = styled.div`
  display: flex;
  width: 100%;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const ItemLine = styled.div`
  display: flex;
  flex-direction: row;
`

export const ItemLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 20px;

  @media (max-width: 1024px) {
    width: 75px;
    height: 60px;
  }
`

export const LabelTitle = styled.span<TimelineStatusStyleProps>`
  text-align: center;
  color: ${props =>
    props.disabled
      ? props.theme.colors.primary
      : props.theme.colors.blue.light};
  font-size: 14px;
  line-height: 17px;

  @media (max-width: 1024px) {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 8px;
    min-height: 26px;
  }
`

export const LabelTime = styled.span<TimelineStatusStyleProps>`
  color: ${props =>
    props.disabled
      ? props.theme.colors.neutral.medium
      : props.theme.colors.blue.light};
  font-size: 14px;
  line-height: 17px;

  @media (max-width: 1024px) {
    font-size: 11px;
    line-height: 13px;
  }
`

export const Circle = styled.div<TimelineStatusStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.primary
      : props.theme.colors.blue.light};
  border-radius: 20px;
  z-index: 1;
  svg {
    width: 16px;
    height: 17px;
    color: white;
  }
`

export const Line = styled.span<TimelineStatusStyleProps>`
  width: 150px;
  height: 2px;
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.primary
      : props.theme.colors.blue.light};
  margin-top: 20px;
  position: absolute;
  @media (max-width: 1024px) {
    width: 75px;
  }
`
