import styled from 'styled-components'

export const RowContainer = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.soft};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }
`

export const Title = styled.span`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;

  color: ${props => props.theme.colors.neutral.medium};
`

export const EventColumn = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`

export const DateColumn = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a7adbf;
`

export const ObservationContainer = styled.div`
  margin-top: 16px;
  border-radius: 4px;
  padding: 12px 16px;
  font-weight: 300;
  background: #f5f5f5;
`
