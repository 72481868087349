import { Card } from 'components/card'
import {
  Container,
  Item,
  LabelSpan,
  QuantityCutItemsValue,
  QuantityItemValue
} from './styles'

type ItemsCardProps = {
  quantidadeItens: number
  quantidadeCortes: number
}

export const ItemsCard = ({
  quantidadeCortes,
  quantidadeItens
}: ItemsCardProps) => (
  <Card title="Itens" maxHeight={140}>
    <Container>
      <Item>
        <LabelSpan>Quant. de itens:</LabelSpan>
        <QuantityItemValue>{quantidadeItens}</QuantityItemValue>
      </Item>
      <Item>
        <LabelSpan>Quant. itens cortados:</LabelSpan>
        <QuantityCutItemsValue>{quantidadeCortes}</QuantityCutItemsValue>
      </Item>
    </Container>
  </Card>
)
