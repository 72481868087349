import { Tag } from './styles'

type TabTitleProps = {
  title: string
  subtitle: string
  active: boolean
}

export const TabTitle = ({ title, subtitle, active }: TabTitleProps) => {
  return (
    <div className="flex flex-col gap-1">
      <div>{title}</div>
      {subtitle && <Tag active={active}>{subtitle}</Tag>}
    </div>
  )
}
