import { DefaultTheme } from 'styled-components'

export const styledComponentTheme: DefaultTheme = {
  name: 'default',
  colors: {
    primary: '#2B52DD',
    secondary: '#004282 ',
    neutral: {
      white: '#FFF',
      light: {
        '300': '#F4F6FC',
        '500': '#C9CAD2'
      },
      soft: '#A7ADBF',
      medium: '#6D7384',
      dark: '#2B2C32'
    },
    suport: {
      success: '#3EB96F',
      warning: '#FBC436',
      error: '#E73A33',
      successLight: '#D8F1E2',
      warningLight: '#FEF3D7',
      errorLight: '#FAD8D6'
    },
    blue: {
      light: '#CFDDF5',
      medium: '#173EC5',
      dark: '#163161'
    },
    gray: {
      light: '#C9CAD2',
      medium: '#EBECF4',
      dark: '#6D6E75'
    }
  }
}
