import Layout from 'common/layout'
import ContentError from 'features/components/content-error'

const DefaultPage = (): JSX.Element => {
  return (
    <Layout time={new Date().getTime()}>
      <ContentError />
    </Layout>
  )
}

export default DefaultPage
