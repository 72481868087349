import styled from 'styled-components'

interface TagProps {
  active: boolean
}

export const Tag = styled.div<TagProps>`
  background: ${prop => (prop.active ? `#CFDDF5` : `#e9ebf1`)};
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
`
